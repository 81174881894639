import React, { useState, useEffect, useRef, ReactElement } from 'react'
import { Checkbox, Dropdown, Input, Button, Avatar } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useClickOutside, useDebounce, useKeyPress } from 'Hooks'
import './index.scss'
import { isArray, set } from 'lodash'
import productImagePlaceholder from 'Assets/images/product_img_placeholder.png'

interface props {
  values?: string[]
  onChange: (value: string[]) => void
  options?: { label: string; value: string, image?: string }[]
  placeholder?: string
  isMulti?: boolean
  handleSearch?: (keyword: string) => void
  customDropdown?: () => ReactElement
  maxWidth?: number,
  segmentType?: string,
}

const SearchDropdown: React.FC<props> = ({
  values,
  options,
  placeholder,
  isMulti,
  customDropdown,
  onChange,
  handleSearch,
  maxWidth,
  segmentType,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined)

  const [isViewMode, setIsViewMode] = useState<boolean>(false)
  const [selectedTempValues, setSelectedTempValues] = useState<string[]>([])
  const [tempValues, setTempValues] = useState<string[]>([])
  const isRenderProductList = ['PRODUCT_COUNT', 'PCM_PURCHASED_PRODUCT'].includes(segmentType!)

  const dropdownRef = useRef(null)
  const ref = useRef(null)

  useClickOutside(ref, () => {
    setIsVisible(false)
    onCloseDropdown()
  }, dropdownRef)
  useKeyPress('Enter', (isKeyPressed: boolean) => {
    setIsVisible(!isKeyPressed)
    onCloseDropdown()
  })
  useKeyPress('Escape', (isKeyPressed: boolean) => {
    setIsVisible(!isKeyPressed)
    onCloseDropdown()
  })
  const debounceSearchQuery = useDebounce(searchQuery, 500)

  const onCloseDropdown = () => {
    setSelectedTempValues([])
    setTempValues([])
    setTimeout(() => {
      setIsViewMode(false)
    }, 200)
  }

  useEffect(() => {
    if (debounceSearchQuery !== undefined && handleSearch) handleSearch(debounceSearchQuery)
  }, [debounceSearchQuery])

  const computeLabel = () => {
    const chosenLabels = (Array?.isArray(values) ? values : [values])?.map(
      (item) => options?.find((op) => op.value === item)?.label || item
    )
    return (
      <div className="truncate w-full">
        {chosenLabels?.length ? (
          isRenderProductList ? `Product (${chosenLabels?.length})` : chosenLabels?.map((item, index) => {
            if (index > 0) {
              item = ', ' + item
            }
            return item
          })
        ) : (
          <span className="text-disabled_text">{placeholder}</span>
        )}
      </div>
    )
  }
  const MENU = (
    <div
      ref={dropdownRef}
      style={{
        minHeight: 'fit-content',
        width: maxWidth || 208,
        maxHeight: 400,
        overflow: 'scroll',
      }}
      className="CommonInputs-multi-select-dropdown flex flex-col bg-gray_1 p-2 customer-segments-filter-dropdown"
    >
      <Input
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      {values?.length ? (
        <div style={{ marginBottom: '8px' }}>
          {(Array?.isArray(values) ? values : [values])?.map((item) => {
            return (
              <div
                style={{
                  cursor: 'pointer',
                  padding: '5px',
                  paddingLeft: '16px',
                  background: 'var(--blue_1)',
                }}
                onClick={() => {
                  const newList = values?.filter((v) => v !== item)
                  onChange(newList)
                }}
              >
                {item}
              </div>
            )
          })}
        </div>
      ) : null}

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {options?.map((option) => (
          <Checkbox
            style={{
              margin: 0,
            }}
            checked={values?.includes(option.value)}
            onChange={() => {
              const isSelected = values?.includes(option.value)
              if (!isMulti) {
                onChange(isSelected ? [] : [option.value])
                return
              }

              const newList = isSelected
                ? values?.filter((item) => item !== option.value) || []
                : [...(values || []), option.value]
              onChange(newList)
            }}
            value={option.value}
          >
            {option.label}
          </Checkbox>
        ))}
      </div>
    </div>
  )

  const PRODUCT_LIST = (
    <div
      ref={dropdownRef}
      style={{
        minHeight: 'fit-content',
        width: Math.max(maxWidth || 0, 328),
        maxHeight: 400,
        overflow: 'scroll',
      }}
      className="CommonInputs-multi-select-dropdown flex flex-col bg-gray_1 p-2 customer-segments-filter-dropdown"
    >
      {!isViewMode && <Input
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />}

      {values?.length ? (
        <div style={{ marginTop: '6px', display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
          <div style={{ fontSize: "14px", lineHeight: "22px", color: "gray" }}>{`${values?.length} selected`}</div>
          {!isViewMode && <Button
            onClick={() => {
              setSelectedTempValues(values || [])
              setTempValues(values || [])
              setIsViewMode(true)
            }}
            type="text"
            className="font-semibold text-brand_primary cursor-pointer p-0"
          >
            View
          </Button>}
        </div>
      ) : null}

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {
          (options || []).filter((option) => isViewMode ? tempValues.includes(option?.value) : true).map((option) => {
            return <div style={{ display: 'flex', alignItems: 'center', height: 60, overflow: 'hidden', paddingTop: 8, paddingBottom: 6 }}>
              <Checkbox
                style={{ margin: 0 }}
                checked={(isViewMode ? selectedTempValues : values)?.includes(option.value)}
                onChange={() => {
                  const isSelected =(isViewMode ? selectedTempValues : values)?.includes(option.value)
                  if (isViewMode) {
                    if (!isMulti) {
                      setSelectedTempValues(isSelected ? [] : [option.value])
                      return
                    }

                    const newList = isSelected
                      ? selectedTempValues?.filter((item) => item !== option.value) || []
                      : [...(selectedTempValues || []), option.value]
                    setSelectedTempValues(newList)
                    return
                  } else {
                    if (!isMulti) {
                      onChange(isSelected ? [] : [option.value])
                      return
                    }

                    const newList = isSelected
                      ? values?.filter((item) => item !== option.value) || []
                      : [...(values || []), option.value]
                    onChange(newList)
                    return
                  }
                }}
                value={option.value}
              />
              <div style={{ width: 40, height: 40, marginLeft: 8, marginRight: 8, flexShrink: 0 }}>
                <Avatar shape='square' src={option?.image ?? productImagePlaceholder} size={40} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='SearchDropdown-product-list-item-label'>{option.label}</div>
                <div className='SearchDropdown-product-list-item-value'>{option.value}</div>
              </div>
            </div>
          })
        }
        {tempValues.length > 0 && isViewMode && <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-around" }}>
          <Button
            onClick={() => {
              onCloseDropdown()
            }}
            style={{ border: '1px solid rgb(230 230 230)' }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              onChange(selectedTempValues)
              onCloseDropdown()
            }}
          >
            Save
          </Button>
        </div>}
      </div>
    </div>
  )

  return (
    <Dropdown
      getPopupContainer={(trigger: any) => trigger.parentNode}
      visible={isVisible}
      trigger={['click']}
      overlay={
        customDropdown ? (
          <div
            ref={dropdownRef}
            style={{
              maxHeight: 400,
              overflow: 'scroll',
              padding: 8,
              boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.12)',
              backgroundColor: 'white',
            }}
          >
            {customDropdown()}
          </div>
        ) : (
          isRenderProductList ? PRODUCT_LIST : MENU
        )
      }
      className="w-full"
    >
      <div
        ref={ref}
        className="py-2 px-3 rounded cursor-pointer flex justify-between items-center border border-gray_5"
        style={{ height: 40, maxWidth: maxWidth || 210 }}
        onClick={() => setIsVisible(true)}
      >
        <span className="flex w-4/5" style={{ minWidth: '100px' }}>
          <span className="w-full">{computeLabel()}</span>
        </span>
        <DownOutlined className="ml-2 text-xs" />
      </div>
    </Dropdown>
  )
}

export default SearchDropdown
